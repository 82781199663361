.Blog {
    width: 100%;
    text-align: center;
    padding: 5px 0;
    display: table;
    align-items: center;
    font-family: Josef;
    justify-content: center;
    z-index: 999;
    align-items: flex-start;
}

.bodyLeft {
    flex: 9;
    display: table-cell;
    justify-content: center;
    align-items: center;
    background-color: pink;
    flex-direction: column;

}



.container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.card {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
}

.card-header img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 20px;
    min-height: 250px;
}
.card-body span{
    width: 110px;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 10px;
    /* align-items: center; */
}

.tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
}

.tag-teal {
    background-color: #47bcd4;
}

.tag-purple {
    background-color: #5e76bf;
}

.tag-pink {
    background-color: #cd5b9f;
}

.card-body p {
    font-size: 13px;
    margin: 0 0 40px;
}

.user {
    display: flex;
    margin-top: auto;
}

.user img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.user-info h5 {
    margin: 0;
}

.user-info small {
    color: #545d7a;
}



/* pagination */

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pagination-container .pagination {
    position: relative;
    height: 60px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    border-radius: 2px;
  }
  .pagination-container .pagination li {
    list-style-type: none;
    display: inline-block;
  }
  .pagination-container .pagination li a {
    position: relative;
    padding: 20px 25px;
    text-decoration: none;
    color:blue;
    font-weight: 500;
  }
  .pagination-container .pagination li a:hover,
  .pagination-container .pagination li.active a {
    background: rgba(255, 255, 255, 0.2);
  }




