.Register {
    background-color: aqua;
    height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background: linear-gradient(rgba(145, 175, 240, 0.5), rgba(255, 255, 255, 0.5)), url("../Login/login-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.RegisterTitle {
    font-size: 40px;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
}

.RegisterForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.RegisterForm>label {
    margin: 10px 0;
    font-size: 22px;
}

.RegisterInput {
    padding: 10px;
    background-color: transparent;
    border-bottom: 1px solid rgb(229, 15, 15);
    border-top: 1px solid rgb(35, 62, 212);
    border-right: 1px solid rgb(54, 205, 82);
    border-left: 1px solid rgb(254, 32, 217);
    color: red;
    border-radius: 5px;
    width: 300px;
}

.RegisterButton {
    margin-top: 50px;
    cursor: pointer;
    background-color: lightcoral;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.LoginButton {
    position: absolute;
    top: 20px;
    right: 150px;
    font-size: 35px;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    padding: 5px;
    background-color: transparent;
    cursor: pointer;
}

.removeUnderline {
    text-decoration: none;
    color: black;
}