.top {
    width: 100%;
    background-color: rgb(13, 0, 255);
    /* height: 40px; */
    text-align: center;
    padding: 5px 0;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: Josef;
    justify-content: center;
    z-index: 999;
}



.topImage {
    width: 50px;
    height: auto;
    border-radius: 20%;
    object-fit: cover;
}

.topSearchIcon {
    font-size: 20px;
    cursor: pointer;
    margin-left: 30px;
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

.snavbar ul {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    color: rgb(234, 228, 228);
    justify-content: space-around;
    font-size: 1rem;
    padding: 0;
    margin-bottom: 0 !important;

}

li {
    list-style-type: none;
    padding: 1rem;
}

.snavbar a {
    text-decoration: none;
    color: rgb(243, 241, 241);
}


.snavbar a.active {

    color: transparent;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 30px;
    text-decoration-color: transparent;
    animation: changeColor 1s ease-in-out forwards;

}

@keyframes changeColor {
    0% {
        text-decoration-color: transparent;
        color: transparent;
        text-underline-offset: 30px;

    }

    100% {
        text-decoration-color: red;
        color: blue;
        text-underline-offset: 8px;

    }

}





.logo-icon {
    background-color: red;
    flex: 3;

}

.snavbar-log2 {
    margin-right: auto;

}

.sicon-log1 {
    margin-left: auto;
    /* to minimize the axis gap, we put logo1 to leave remaining space on the left side */

}




.menu {
    background-color:aqua;
    flex: 6;
    /* justify-content: center; */

}

.sicon {
    background-color: rgb(64, 181, 161);
    flex: 3;
    /* justify-content: start; */

}



.show-later {
    display: none;
}



.sidebarIconToggle {
    width: 10px;
    height: 10px;
    background-color: aqua;
    cursor: pointer;
}

/* spinner bar start*/

.spinner {
    display: none;
}

.spinner-bar.top,
.spinner-bar.middle,
.spinner-bar.bottom {
    background: red;
    width: 13px;
    height: 3px;
    display: block;
    cursor: pointer;
    border-radius: 5px;
}

.spinner-bar.top {
    margin-left: 12px;
    padding: 0;
}

.spinner-bar.bottom {
    width: 25px;
    height: 3px;
    margin-top: 5px;

}

.spinner-bar.middle {
    background: #fff;
    width: 20px;
    margin-left: 5px;
    margin-top: 5px;
}

.ax {
    display: none;
}

.ax.rotateX {
    display: block;
    font-size: 20px;
    color: red;
    animation: slide-inX 0.3s ease-in-out;
}

@keyframes slide-inX {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);

    }
}

.spinner.rotateHam {
    animation: slide-out1 0.3s ease-in-out;
}

@keyframes slide-out1 {
    0% {
        transform: rotate(360deg);

    }

    100% {
        transform: rotate(0);
    }
}


.spinner-bar.top.toggleTop, .spinner-bar.middle.toggleMdl, .spinner-bar.bottom.toggleBtm {
    display: none;
}




@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

/* To hide the menuitems */
@keyframes slide-out {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
        display: none;
    }
}



/* spinner coding end */

/* Now, we will hide the menu bar when screen size is 750px as it collides */
/* 800px screen pe do line pe ho jayeg */
/* 800px screen pe ek hi line pe rahega if nowrap karte hai to  */
/* since we have to cover the entire width so position absolute taken */
@media screen and (max-width:750px) {
    .menu {
        flex-wrap: wrap;
        position: absolute;
        top:111px;
        /* because height of the .snavbar ul is 60px */
    }


ul.menu.toggleMenu {
    /* animation: slide-in 0.3s ease-in-out; */
    transform: translateX(0);
    z-index: 999;
    height: 100vh;
    align-items: center;
    height: calc(100vh - 111px);
    transition: 0.3s ease;


}


ul.menu:not(.toggleMenu) {
    transform: translateX(100%);
    /* animation: slide-out 0.3s ease-in-out forwards; */
    position: absolute;
    z-index: 999;
    align-items: center;
    height: calc(100vh - 111px);
    transition: 0.3s ease;
}

    .sicon-log1,
    .sicon-log2,
    .sicon-log3 {
        display: none;
    }

    .snavbar-links span {
        display: inline-block;
        padding: 0 1rem;
    }

    .toggle-social {
        display: block;
    }

    .show-later {
        display: block;
    }


    .spinner {
        display: block;
        margin-left: auto;
        cursor: pointer;

        /* as we want to leave space on the left hand side */
    }


    /* since we have taken flex-wrap: wrap; to ul tag that will bring into two line when screen size is 800px and when we take
  with 100% to the menulinks or snavbar-links(here), it will be in the second line */
    .snavbar-links {
        width: 100%;
        background-color: aqua;
        text-align: center;
        display: block;
        /* we will display on clicking on the toggle button */

    }


    .snavbar-links:hover {
        background-color: rgb(233, 231, 244);

    }


    .snavbar-links:hover a{
        color:red;

    }



}



/* .Navlink {
    color: #dc3535;
    text-decoration: none;
} */