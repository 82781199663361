/* sidebar css */

.MainSidebar {
    flex: 3;
    display:table-cell;
    text-align: center;
    background: linear-gradient(blue, white, orange, pink, yellow);
    border-radius: 10px;
    z-index: -1;
    padding: 30px 0;
    /* height:100vh; */


}

.sidebarImage {
    display: inline-block;
    width: 400px;
    height: 300px;
    margin: 10px 30px;
    overflow: hidden;
}

.sidebarTitle {
    margin: 40px 30px;
    display: block;
    border-top: 2px solid rgba(139, 133, 133, 0.826);
    border-bottom: 2px solid rgba(139, 133, 133, 0.826);
    padding: 10px 0;
    font-family: "Jersey 25", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color: #dc3535;
}

.sidebarListItem {
    list-style: none;
    margin-bottom: 20px;
    cursor: pointer;
}

.sidebarSocial {
    display: flex;
    justify-content: space-evenly;
}


.sidbarIcons {
    font-size: 25px;
    color: blue;
    display: block;


}



@media screen and (max-width:784px) {

    .MainSidebar {
        padding: 20px 0;
    }

}



@media screen and (max-width:784px) {

    .MainSidebar {
        width: 170px;

    }

}


@media screen and (max-width:400px) {

    .MainSidebar {
        width: 120px;

    }

}